module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["sk"],"defaultLanguage":"sk","siteUrl":"https://dajbajk.onrender.com","redirect":false,"pages":[{"matchPath":"/:lang?","getLanguageFromPath":true},{"matchPath":"/:lang?/:slug","getLanguageFromPath":true}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dajbajk","short_name":"Dajbajk","start_url":"/","background_color":"#F5F5F5","theme_color":"#FF9176","display":"standalone","icon":"/opt/render/project/src/dajbajk/src/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"09147a4169aca48816c72cd0af4c066b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
