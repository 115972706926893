import {fonts} from 'uikit'
import {transparentize} from 'polished'
import texture from './assets/texture.svg'


const ORANGE = '#FF9176'
const BLACK = '#050505'
const WHITE = '#FFFFFF'
const GRAY = '#BEBEBE'
const DARK_GREEN = '#1A4740'
const GREEN = '#002B22'
const DARK_SLATE_GRAY = '#385D56'
const LIGHT_GREEN = '#CDD8D2'
const RED = '#FF0000'

const theme = {
  colors: {
    primary: ORANGE,
    secondary: DARK_GREEN,
    text: DARK_GREEN,
    text2: WHITE,
    text3: BLACK,
    background: LIGHT_GREEN,
    background2: WHITE,
    background3: DARK_SLATE_GRAY,
    background4: GREEN,
    aux: GRAY,
    negative: RED,
  },
  texture: {
    src: texture,
    repeat: false,
  },
  fonts: {
    family: {
      primary: fonts.supremeRegular.name,
      secondary: fonts.supremeExtraBold.name,
    },
  },
  overlays: {
    background: transparentize(0.5, BLACK),
    backgroundNoBlur: transparentize(0.3, BLACK),
    backgroundNoBlurCookieBar: transparentize(0.125, BLACK),
  },
  border: {
    default: '1px solid',
    radius: {
      xs: '0.25rem',
      sm: '0.5rem',
      md: '0.5rem',
      lg: '1.25rem',
      xl: '1.75rem',
    },
  },
}

export default theme
